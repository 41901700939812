import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import * as din from '../../lib/calculator.js';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  form: FormGroup = new FormGroup({
    level: new FormControl("I"),
    age: new FormControl("16-50"),
    weight: new FormControl("67-78"),
    height: new FormControl("167-178"),
    size: new FormControl("271-290")
  });

  result:any;

  constructor(private router: Router) { }

  ngOnInit() {
    let data = localStorage.getItem("data");
    // console.log(data);
    if (data) {
      this.form.patchValue(JSON.parse(data));
    }

    // this.result = din.calculate(this.form.get("age").value, this.form.get("level").value, this.form.get("weight").value, this.form.get("height").value, this.form.get("size").value);
    // this.onChanges();
  }

  onSubmit() {
    let data = this.form.getRawValue();
    localStorage.setItem("data", JSON.stringify(data));
    let route = this.router.config.find(r => r.path === 'result');
    route.data = {};
    route.data['params'] = data;
    let result = din.calculate(this.form.get("age").value, this.form.get("level").value, this.form.get("weight").value, this.form.get("height").value, this.form.get("size").value);
    route.data['din'] = result;
    this.router.navigateByUrl('result');
  }

  // private onChanges() {
  //   this.form.valueChanges.subscribe(val => {
  //     console.log(val);
  //     this.result = din.calculate(this.form.get("age").value, this.form.get("level").value, this.form.get("weight").value, this.form.get("height").value, this.form.get("size").value);
  //     console.log(this.result);
  //   });
  // }

}
